// src/components/Logs.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';  // Import moment.js to format dates
import { BASE_URL } from './constants';

function Logs() {
  const [transactions, setTransactions] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editForm, setEditForm] = useState({
    id: '',
    description: '',
    amount: '',
    transaction_type: 'credit',
    transaction_mode: 'upi',
    entry_type: 'manual', // Default value, adjust if needed
    entry_status: 'approved',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/transactions`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });

        if (response.data.success) {
          setTransactions(response.data.data);
        } else {
          setError('Failed to fetch transactions');
        }
      } catch (error) {
        setError('An error occurred while fetching transactions');
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const handleEditClick = (transaction) => {
    setEditingId(transaction.id);
    setEditForm({
      id: transaction.id,
      description: transaction.description,
      amount: transaction.amount,
      transaction_type: transaction.transaction_type,
      transaction_mode: transaction.transaction_mode,
      entry_type: transaction.entry_type, // Preserve original value
      entry_status: transaction.entry_status,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(`${BASE_URL}/update_transaction`, editForm, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        // Update the transaction in the state
        setTransactions(transactions.map(t => t.id === editForm.id ? { ...t, ...editForm } : t));
        setEditingId(null);
      } else {
        setError('Failed to update transaction');
      }
    } catch (error) {
      setError('An error occurred while updating transaction');
      console.error('Error updating transaction:', error);
    }
  };

  const handleCancel = () => {
    setEditingId(null);
  };

  // Function to format date_time using moment.js
  const formatDateTime = (dateTime) => {
    return moment(dateTime).format('MMMM Do YYYY, h:mm:ss A'); // Example: August 17th 2024, 3:58:59 PM
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="logs-container">
      <h2>Transaction Logs</h2>
      {transactions.length === 0 ? (
        <p>No transactions found.</p>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Mode</th>
              <th>Entry Type</th>
              <th>Entry Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                {editingId === transaction.id ? (
                  <>
                    <td>
                      <input
                        type="text"
                        name="description"
                        value={editForm.description}
                        onChange={handleChange}
                      />
                      <div style={{ color: 'grey', fontSize: '0.8em' }}>
                        {formatDateTime(transaction.date_time)} {/* Display formatted date_time below description */}
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        name="amount"
                        value={editForm.amount}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <select
                        name="transaction_type"
                        value={editForm.transaction_type}
                        onChange={handleChange}
                      >
                        <option value="credit">Credit</option>
                        <option value="debit">Debit</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="transaction_mode"
                        value={editForm.transaction_mode}
                        onChange={handleChange}
                      >
                        <option value="upi">UPI</option>
                        <option value="card">Card</option>
                        <option value="cash">Cash</option>
                        <option value="ukn">Unknown</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="entry_type"
                        value={editForm.entry_type}
                        disabled
                      />
                    </td>
                    <td>
                      <select
                        name="entry_status"
                        value={editForm.entry_status}
                        onChange={handleChange}
                      >
                        <option value="approved">Approved</option>
                        <option value="pending">Pending</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </td>
                    <td>
                      <button onClick={handleSave}>Save</button>
                      <button onClick={handleCancel}>Cancel</button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      {transaction.description}
                      <div style={{ color: 'grey', fontSize: '0.8em' }}>
                        {formatDateTime(transaction.date_time)} {/* Display formatted date_time */}
                      </div>
                    </td>
                    <td>{transaction.amount}</td>
                    <td>{transaction.transaction_type}</td>
                    <td>{transaction.transaction_mode}</td>
                    <td>{transaction.entry_type}</td>
                    <td>{transaction.entry_status}</td>
                    <td>
                      <button onClick={() => handleEditClick(transaction)}>Edit</button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Logs;
