// src/Profile.js
import React, { useState } from 'react';
import '../styles/Profile.css'; // Import custom styling
import { useUser } from '../UserContext';

function Profile() {
  const [copied, setCopied] = useState(false);
  // fetching data from the user content that is being updated by Home.js by calling '/' api of python.
  const { profileCode, firstName, lastName } = useUser(); 

  const handleCopy = () => {
    navigator.clipboard.writeText(profileCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  return (
    <div className="profile-container">
      <h1>My Profile</h1>
      <p><strong>Full Name:</strong> {firstName} {lastName}</p>
      <div className="profile-code-container">
        <p><strong>Profile Code:</strong> {profileCode}</p>
        <button className="copy-button" onClick={handleCopy}>
          {copied ? "Copied!" : "Copy"}
        </button>
      </div>
    </div>
  );
}

export default Profile;
