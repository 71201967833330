// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Profile.css */
.profile-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: left; /* Align text to the left */
    /* border: 1px solid #ddd; Optional: Add a border around the profile container */
    /* border-radius: 10px; Optional: Rounded corners */
    /* background-color: #f9f9f9; Optional: Light background */
  }
  
  .profile-container h1 {
    margin-bottom: 20px;
  }
  
  .profile-container p {
    font-size: 18px;
  }
  
  .profile-code-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .copy-button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .copy-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Profile.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,gBAAgB,EAAE,2BAA2B;IAC7C,gFAAgF;IAChF,mDAAmD;IACnD,0DAA0D;EAC5D;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/Profile.css */\n.profile-container {\n    padding: 20px;\n    max-width: 600px;\n    margin: 0 auto;\n    text-align: left; /* Align text to the left */\n    /* border: 1px solid #ddd; Optional: Add a border around the profile container */\n    /* border-radius: 10px; Optional: Rounded corners */\n    /* background-color: #f9f9f9; Optional: Light background */\n  }\n  \n  .profile-container h1 {\n    margin-bottom: 20px;\n  }\n  \n  .profile-container p {\n    font-size: 18px;\n  }\n  \n  .profile-code-container {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n  }\n  \n  .copy-button {\n    margin-left: 10px;\n    padding: 5px 10px;\n    font-size: 16px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .copy-button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
