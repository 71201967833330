// src/components/Navbar.js
import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios
import { useUser } from '../UserContext'; // Import useUser from UserContext

function CustomNavbar() {
    const navigate = useNavigate();
    const { username } = useUser(); // Use the context to get the username

    const token = localStorage.getItem('token'); // Or wherever you store the JWT

    const handleLogout = async () => {
        try {
            await axios.post(
                'https://budgetmanagement-api.cannyware.com/logout',
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                    withCredentials: true, // Ensure cookies and credentials are sent
                }
            );
            localStorage.removeItem('token');
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand as={Link} to="/">
                Cannyware
                <div style={{ fontSize: '12px', color: '#bbb' }}>Budget Management</div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="d-flex justify-content-between">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/logs">Logs</Nav.Link>
                    <Nav.Link as={Link} to="/add-entry">Add Entry</Nav.Link>
                </Nav>
                <Nav>
                    <NavDropdown title={username || "Username"} id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/profile">My Profile</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default CustomNavbar;
