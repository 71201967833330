// src/FindUser.js
import React from 'react';

function FindUser() {
  return (
    <div>
      <h1>Find User</h1>
      <p>Search for users here.</p>
    </div>
  );
}

export default FindUser;
