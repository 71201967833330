import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/MainScreen.css'; // Import your CSS file for styles
import budgetImage from '../assets/mainscreen-pic-01.png';
import aiImage from '../assets/autoentry.jpeg'; // Add your AI image path
import forecastImage from '../assets/temp_forecast.jpg'; // Updated forecasting image path

const MainScreen = () => {
  return (
    <div className="main-screen">
      {/* Navbar Section */}
      <div className="navbar">
        <div className="navbar-title">Budget Management by Cannyware</div>
        <div className="navbar-links">
        <Link to="/home" className="nav-link">Try without login</Link>
          <Link to="/login" className="nav-link">Login</Link>
          <Link to="/signup" className="nav-link">Signup</Link>
        </div>
      </div>

      {/* First Text Container with Image on Right */}
      <div className="text-container">
        <div className="text-content">
          <h1>Welcome to Your Budget Management System</h1>
          <p>
            Manage your finances effectively with our intuitive platform. 
            Track your expenses, set budgets, and achieve your financial goals with ease.
          </p>
        </div>
        <div className="image-container">
          <img src={budgetImage} alt="Budget Management" />
        </div>
      </div>

      {/* Second Text Container for Auto Entry Using AI */}
      <div className="text-container-2">
        <div className="ai-image-container">
          <img src={aiImage} alt="Auto Entry Using AI" />
        </div>
        <div className="ai-text-container">
          <h2>Auto Entry Using AI</h2>
          <p>
            AI will find financial SMS and auto-enter those records into your profile, 
            so you don't have to add each and every transaction manually.
          </p>
        </div>
      </div>

      {/* Third Text Container for Forecasting Using AI with Image on Right */}
      <div className="text-container-3">
        <div className="forecast-text-container">
          <h2>Forecasting Using AI</h2>
          <p>
            Leverage AI to predict future spending patterns and help you plan your budgets 
            more effectively. Stay ahead of your financial goals with smart insights!
          </p>
        </div>
        <div className="forecast-image-container">
          <img src={forecastImage} alt="Forecasting Using AI" />
        </div>
      </div>
    </div>
  );
};

export default MainScreen;
