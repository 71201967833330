// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from './constants.js';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BASE_URL}/login`, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.success) {
                // Store the token in localStorage
                localStorage.setItem('token', response.data.access_token);
                navigate('/home'); // Redirect to home
            } else {
                alert('Login failed: ' + response.data.message);
            }
        } catch (error) {
            console.error('Login failed', error);
            alert('An error occurred during login');
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder="Enter your email"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            placeholder="Enter your password"
                        />
                    </div>
                    <button type="submit" className="btn btn-dark btn-block">Login</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
