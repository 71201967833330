// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-table {
    width: auto; /* Change from 100% to auto */
    border-collapse: collapse;
    margin-bottom: 20px; /* Space between table and charts */
    max-width: 100%; /* Prevent overflow */
}

.summary-table th,
.summary-table td {
    border: 1px solid #ddd; /* Light gray border */
    padding: 4px; /* Reduced cell padding for a smaller table */
    text-align: left; /* Align text to the left */
}

.summary-table th {
    background-color: #f2f2f2; /* Light background for headers */
    font-weight: bold; /* Bold text for headers */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Home.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,6BAA6B;IAC1C,yBAAyB;IACzB,mBAAmB,EAAE,mCAAmC;IACxD,eAAe,EAAE,qBAAqB;AAC1C;;AAEA;;IAEI,sBAAsB,EAAE,sBAAsB;IAC9C,YAAY,EAAE,6CAA6C;IAC3D,gBAAgB,EAAE,2BAA2B;AACjD;;AAEA;IACI,yBAAyB,EAAE,iCAAiC;IAC5D,iBAAiB,EAAE,0BAA0B;AACjD","sourcesContent":[".summary-table {\n    width: auto; /* Change from 100% to auto */\n    border-collapse: collapse;\n    margin-bottom: 20px; /* Space between table and charts */\n    max-width: 100%; /* Prevent overflow */\n}\n\n.summary-table th,\n.summary-table td {\n    border: 1px solid #ddd; /* Light gray border */\n    padding: 4px; /* Reduced cell padding for a smaller table */\n    text-align: left; /* Align text to the left */\n}\n\n.summary-table th {\n    background-color: #f2f2f2; /* Light background for headers */\n    font-weight: bold; /* Bold text for headers */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
