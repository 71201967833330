// src/AddFamily.js
import React, { useState } from 'react';
import '../styles/AddFamily.css';

function AddFamily() {
  const [familyMember, setFamilyMember] = useState({
    firstName: '',
    lastName: '',
    relation: '',
    age: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFamilyMember({ ...familyMember, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Family Member Added:', familyMember);
    // Handle form submission, e.g., send data to the server
    setFamilyMember({ firstName: '', lastName: '', relation: '', age: '' });
  };

  return (
    <div className="container">
      <h1>Add Family Member</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            className="form-control"
            name="firstName"
            value={familyMember.firstName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            className="form-control"
            name="lastName"
            value={familyMember.lastName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Relation</label>
          <input
            type="text"
            className="form-control"
            name="relation"
            value={familyMember.relation}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Age</label>
          <input
            type="number"
            className="form-control"
            name="age"
            value={familyMember.age}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Member</button>
      </form>
    </div>
  );
}

export default AddFamily;
