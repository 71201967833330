// src/components/Layout.js
import React from 'react';
import CustomNavbar from './Navbar'; // Import Navbar component

const Layout = ({ showNavbar = true, children }) => {
  return (
    <div>
      {showNavbar && <CustomNavbar />} {/* Conditionally render Navbar */}
      <main>
        {children}
      </main>
    </div>
  );
};

export default Layout;
