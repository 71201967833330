// src/Messages.js
import React from 'react';

function Messages() {
  return (
    <div>
      <h1>Messages</h1>
      <p>View your messages here.</p>
    </div>
  );
}

export default Messages;
