// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* For larger screens, align chart to the left with a fixed width */
.echart-container {
    width: 40%; /* Set a fixed width for desktop */
    height: 400px;
    float: left; /* Align the chart to the left */
    margin-right: 20px; /* Add some spacing to the right for the next chart */
}

/* For mobile devices, make the chart take full width */
@media screen and (max-width: 768px) {
    .echart-container {
        width: 100%; /* Take full width on mobile */
        height: 300px; /* Adjust height for better view on mobile */
        float: none; /* Center the chart on mobile */
        margin-right: 0; /* Remove right margin for mobile */
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/EChartComponent.css"],"names":[],"mappings":"AAAA,mEAAmE;AACnE;IACI,UAAU,EAAE,kCAAkC;IAC9C,aAAa;IACb,WAAW,EAAE,gCAAgC;IAC7C,kBAAkB,EAAE,qDAAqD;AAC7E;;AAEA,uDAAuD;AACvD;IACI;QACI,WAAW,EAAE,8BAA8B;QAC3C,aAAa,EAAE,4CAA4C;QAC3D,WAAW,EAAE,+BAA+B;QAC5C,eAAe,EAAE,mCAAmC;IACxD;AACJ","sourcesContent":["/* For larger screens, align chart to the left with a fixed width */\n.echart-container {\n    width: 40%; /* Set a fixed width for desktop */\n    height: 400px;\n    float: left; /* Align the chart to the left */\n    margin-right: 20px; /* Add some spacing to the right for the next chart */\n}\n\n/* For mobile devices, make the chart take full width */\n@media screen and (max-width: 768px) {\n    .echart-container {\n        width: 100%; /* Take full width on mobile */\n        height: 300px; /* Adjust height for better view on mobile */\n        float: none; /* Center the chart on mobile */\n        margin-right: 0; /* Remove right margin for mobile */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
