// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.signup-form {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    background-color: #f9f9f9;
}

.form-group {
    margin-bottom: 15px;
}

.form-control {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.btn:disabled {
    background-color: #888;
}

.error-message {
    color: red;
    margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Signup.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":[".signup-container {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n}\n\n.signup-form {\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    padding: 20px;\n    background-color: #f9f9f9;\n}\n\n.form-group {\n    margin-bottom: 15px;\n}\n\n.form-control {\n    width: 100%;\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n}\n\n.btn {\n    width: 100%;\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n    background-color: #333;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.btn:disabled {\n    background-color: #888;\n}\n\n.error-message {\n    color: red;\n    margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
