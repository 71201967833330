import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import axios from 'axios';
import '../styles/EChartComponent.css'; // Import the CSS file
import { BASE_URL } from './constants';


function EChartComponent() {
    const chartRef = useRef(null);
    const pieChartRef = useRef(null); // Reference for the pie chart
    const [chartData, setChartData] = useState({ dates: [], values: [] });
    const [modeCounts, setModeCounts] = useState({}); // State for transaction mode counts

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/current-month-transactions`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (response.status === 200) {
                    const { transactions, transaction_mode_counts } = response.data;
                    const dates = transactions.map(t => t.date);
                    const values = transactions.map(t => t.total_amount);

                    setChartData({ dates, values });
                    setModeCounts(transaction_mode_counts); // Set mode counts
                } else {
                    alert('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (chartData.dates.length > 0) {
            const chart = echarts.init(chartRef.current);

            chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    show: true
                },
                legend: {
                    data: ['Current Month Expenses']
                },
                xAxis: {
                    type: 'category',
                    data: chartData.dates,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: 'Current Month Expenses',
                        data: chartData.values,
                        type: 'line',
                    },
                ],
            });

            const handleResize = () => {
                chart.resize();
            };

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                chart.dispose();
            };
        }
    }, [chartData]);

    useEffect(() => {
        if (Object.keys(modeCounts).length > 0) {
            const pieChart = echarts.init(pieChartRef.current);
    
            pieChart.setOption({
                title: {
                    text: 'Transaction Modes',
                    left: 'center', // Center the title
                    top: 'top', // Position it at the top
                    textStyle: {
                        fontSize: 20, // Adjust font size as needed
                        fontWeight: 'bold'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: 'Transaction Modes',
                        type: 'pie',
                        radius: '50%',
                        data: Object.entries(modeCounts).map(([mode, count]) => ({
                            name: mode,
                            value: count
                        })),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
    
            const handleResize = () => {
                pieChart.resize();
            };
    
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                pieChart.dispose();
            };
        }
    }, [modeCounts]);
    

    return (
        <div className="charts-container">
            <div ref={chartRef} className="echart-container" />
            <div ref={pieChartRef} className="echart-container" />
        </div>
    );
}

export default EChartComponent;
