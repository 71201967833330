/*
for protected route it will check 
token is found in browser local storage
yes: allow access to that endpoint
no: redirect to login page.
*/

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
    const token = localStorage.getItem('token');

    // If token is not present, redirect to login page
    if (!token) {
        return <Navigate to="/login" />;
    }

    // Render the component if token is present
    return element;
};

export default ProtectedRoute;
