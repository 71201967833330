import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EChartComponent from './EChartComponent';
import { useUser } from '../UserContext';
import { useMediaQuery } from 'react-responsive';
import '../styles/Home.css'; // Import the CSS file
import { BASE_URL } from './constants.js';


function Home() {
  const { income, expense, setUsername, setIncome, setExpense, setProfileCode, setFirstName, setLastName } = useUser();
  const navigate = useNavigate();
  const [todayExpense, setTodayExpense] = useState('');
  const [showSummary, setShowSummary] = useState(false); // State to control table visibility

  // Define media queries for responsiveness
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 769px)' });

  const fetchSummary = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(BASE_URL, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUsername(response.data.username);
        setIncome(response.data.income);
        setExpense(response.data.expense);
        setProfileCode(response.data.profile_code);
        setFirstName(response.data.first_name);
        setLastName(response.data.last_name);
        setTodayExpense(response.data.today_expense);
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching summary:', error);
      navigate('/login');
    }
  }, [navigate, setUsername, setIncome, setExpense, setProfileCode, setFirstName, setLastName]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const toggleSummary = () => {
    setShowSummary(prev => !prev); // Toggle the summary visibility
  };

  return (
    <div className="home-container">
      <div className="home-summary">
        <button onClick={toggleSummary}>
          {showSummary ? 'Hide Summary' : 'Show Summary'}
        </button>
        {showSummary && (
          <table className="summary-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Income</td>
                <td>{income}</td>
              </tr>
              <tr>
                <td>Total Expense</td>
                <td>{expense}</td>
              </tr>
              <tr>
                <td>Today's Expense</td>
                <td>{todayExpense}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      {/* Conditionally render based on screen size */}
      {isMobile && (
        <div className="mobile-view">
          <EChartComponent />
        </div>
      )}

      {isDesktopOrLaptop && (
        <div className="desktop-view">
          <EChartComponent />
        </div>
      )}
    </div>
  );
}

export default Home;
