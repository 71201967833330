// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './components/Login';
import Home from './components/Home';
import Logs from './components/Logs';
import EChartComponent from './components/EChartComponent';
import AddEntry from './components/AddEntry';
import Profile from './components/Profile';
import { UserProvider } from './UserContext'; // Adjust the import path
import ProtectedRoute from './components/ProtectedRoute';
import Signup from './components/Signup';
import MainScreen from './components/MainScreen'; 

function App() {
    return (
        <UserProvider>
            <Router>
                <Routes>
                <Route path="/" element={<MainScreen />} /> {/* Load MainScreen first */}
                    <Route path="/login" element={<Layout showNavbar={false}><Login /></Layout>} />
                    <Route path="/signup" element={<Layout showNavbar={false}><Signup /></Layout>} />
                    <Route path="/home" element={<ProtectedRoute element={<Layout><Home /></Layout>} />} />
                    <Route path="/chart" element={<ProtectedRoute element={<Layout><EChartComponent /></Layout>} />} />
                    <Route path="/logs" element={<ProtectedRoute element={<Layout><Logs /></Layout>} />} />
                    <Route path="/add-entry" element={<ProtectedRoute element={<Layout><AddEntry /></Layout>} />} />
                    <Route path="/profile" element={<ProtectedRoute element={<Layout><Profile /></Layout>} />} />
                </Routes>
            </Router>
        </UserProvider>
    );
}

export default App;
