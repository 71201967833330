// src/components/AddEntry.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddEntry() {
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('credit'); // default value
  const [transactionMode, setTransactionMode] = useState('upi'); // default value
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://budgetmanagement-api.cannyware.com/add_transaction', {
        description,
        amount,
        transaction_type: transactionType,
        transaction_mode: transactionMode
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success) {
        // Redirect or show a success message
        navigate('/'); // Redirect to home or another page
      } else {
        alert('Failed to add transaction');
      }
    } catch (error) {
      console.error('Error adding transaction:', error);
      alert('An error occurred while adding the transaction');
    }
  };

  return (
    <div className="add-entry-container">
      <div className="add-entry-form">
        <h2>Add Transaction</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              placeholder="Enter description"
            />
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="form-control"
              placeholder="Enter amount"
            />
          </div>
          <div className="form-group">
            <label htmlFor="transactionType">Transaction Type:</label>
            <select
              id="transactionType"
              value={transactionType}
              onChange={(e) => setTransactionType(e.target.value)}
              className="form-control"
            >
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="transactionMode">Transaction Mode:</label>
            <select
              id="transactionMode"
              value={transactionMode}
              onChange={(e) => setTransactionMode(e.target.value)}
              className="form-control"
            >
              <option value="upi">UPI</option>
              <option value="card">Card</option>
              <option value="cash">Cash</option>
              <option value="ukn">Unknown</option>
            </select>
          </div>
          <button type="submit" className="btn btn-dark btn-block">Add Transaction</button>
        </form>
      </div>
    </div>
  );
}

export default AddEntry;
