// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/AddFamily.css */
.container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/AddFamily.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* src/AddFamily.css */\n.container {\n    padding: 20px;\n    max-width: 600px;\n    margin: 0 auto;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
